@use 'utils' as media;

time {
  display: flex;
  align-items: center;
  gap: 1em;
  border-top: var(--border);

  @include media.desktop {
    font-size: 1.3em;
  }

  span {
    text-transform: capitalize;

    &#day {
      font-size: 5em;
      line-height: 1;
      text-align: center;
      width: 2ch;
    }

    &.date {
      font-size: larger;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      span {
        padding: 0.1em 0;

        &:not(:last-child) {
          border-bottom: var(--border);
        }
      }
    }
  }
}
