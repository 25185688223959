div#flash {
  position: absolute;
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  z-index: -1;
}

button#download {
  grid-area: controls;
  align-self: flex-end;
  justify-self: flex-start;
  position: relative;

  cursor: pointer;
  color: transparent;
  width: 2em;
  height: 1em;
  background-color: var(--secondary-color);
  border: var(--border);
  margin-left: 1em;
  transition: height 100ms cubic-bezier(0, 0.59, 0.98, 0.43);

  &:before {
    content: '↓';
    font-size: 1.5em;
    font-family: 'DM Sans';
    position: absolute;
    top: -1.5em;
    left: 0.2em;
    color: var(--secondary-color);
  }
}
