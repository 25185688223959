@use 'utils' as media;

blockquote {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  height: 100%;

  p {
    margin-bottom: 0.5em;
  }

  footer {
    font-size: 0.8em;
    font-style: italic;
    margin-top: auto;
    padding: 0;
  }

  @include media.desktop {
    font-size: 2.5em;

    footer {
      font-size: 0.6em;
    }
  }
}
