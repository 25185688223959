@use 'utils' as media;

div#card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: var(--border);
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 1em 1em 0.4em;
  height: 100%;
  width: 100%;

  &:after {
    content: 'today is today';
    font-family: 'DM Sans';
    font-size: smaller;
    position: absolute;
    padding: 0.75em 1em;
    bottom: 0;
    right: 0;
    border-left: 1px solid var(--secondary-color);
    opacity: 0.8;
  }

  @include media.desktop {
    &:after {
      font-size: medium;
    }
  }
}
