@use 'utils' as media;
@use 'sass:color';

#light-switch {
  grid-area: controls;
  justify-self: flex-end;
  cursor: pointer;

  display: flex;

  label {
    font-family: 'DM Sans', sans-serif;
    cursor: inherit;
    color: var(--secondary-color);
  }
}

input[type='checkbox'] {
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  cursor: inherit;
  touch-action: inherit;

  height: 80%;
  transition: height cubic-bezier(1, -0.8, 0.23, 1.48) 500ms;

  &:checked {
    height: 60%;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible:after {
    outline: 2px solid var(--secondary-color);
    outline-offset: 1px;
  }

  &:before,
  &:after {
    content: '';
    height: 100%;
    display: block;
  }

  &:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    align-self: flex-end;
  }

  &:before {
    border-left: 2px dotted var(--secondary-color);
    // (:after width / 2) + (:before border-width / 2) = 7px
    transform: translate(7px);
  }
}
