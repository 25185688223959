body > footer {
  grid-area: footer;
  align-self: flex-end;

  p {
    font-family: 'DM Sans';
    text-align: center;
    color: var(--secondary-color);
    padding: 0.8em 1em;

    a {
      color: inherit;

      &:hover {
        text-decoration-style: wavy;
      }
    }
  }
}
