button#refresh {
  grid-area: footer;
  align-self: center;
  justify-self: center;
  position: relative;
  cursor: pointer;
  color: transparent;
  background-color: transparent;

  border: var(--border);
  border-top-color: transparent;
  border-radius: 50%;

  height: 24px;
  width: 24px;

  transform: rotate(45deg);
  transition: transform 300ms ease-in-out;
  transition-timing-function: ease-in;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: -5px;

    border: var(--border);
    border-left-width: 0;
    border-bottom-width: 0;

    height: 8px;
    width: 8px;
  }

  &.refresh {
    animation: roll 500ms ease-in;
  }
}

@keyframes roll {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(365deg);
  }
}
