@use './styles';

body {
  --primary-color: gold;
  --secondary-color: black;
  --background-colour: beige;
  --border: 1px solid var(--secondary-color);

  &.dark {
    --primary-color: black;
    --secondary-color: gold;
    --background-color: black;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 2px solid var(--secondary-color);
    outline-offset: 1px;
  }
}

body {
  min-height: 100vh;
  font-family: 'DM Serif Display';
  background-color: var(--background-color, beige);
  overflow: hidden;

  main {
    grid-area: content;
    display: flex;
    position: relative;
  }
}
